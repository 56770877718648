<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back(-2)">直播管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back(-1)">班级管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.go(0)"
            >{{ essentialInformationData.userName }}-认证记录</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName">
          <el-tab-pane label="认证记录" name="first">
            <!-- 基本数据 -->
            <div class="infoDatas">
              <span class="s1">姓名：</span>
              <span class="s2">{{ essentialInformationData.userName }}</span>
              <span class="s1">身份证号：</span>
              <span class="s2">{{ essentialInformationData.idcard }}</span>
              <span class="s1">电话：</span>
              <span class="s2">{{ essentialInformationData.mobile }}</span>
            </div>
            <!-- 检索数据 -->
            <el-form
              ref="retrievalData"
              :model="retrievalData"
              label-width="100px"
              inline
            >
              <el-form-item label="直播课节名称" prop="liveLessionName">
                <el-input
                  v-model="retrievalData.liveLessionName"
                  placeholder="直播课节名称"
                  clearable
                  size="small"
                />
              </el-form-item>

              <el-form-item label="认证类型" :label-width="80" prop="faceType">
                <el-select
                  v-model="retrievalData.faceType"
                  placeholder="请选择"
                  clearable
                  size="small"
                >
                  <el-option
                    v-for="item in livefaceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="认证状态" :label-width="80" prop="faceState">
                <el-select
                  v-model="retrievalData.faceState"
                  placeholder="请选择"
                  clearable
                  size="small"
                >
                  <el-option
                    v-for="item in faceStateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="operation">
                <el-button
                  style="width: 80px"
                  type="primary"
                  size="small"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
            <!-- 列表数据 -->
            <div class="framePage-scroll">
              <div class="ovy-a">
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  :height="tableHeight"
                  size="small"
                  tooltip-effect="dark"
                  style="width: 100%"
                  :header-cell-style="tableHeader"
                  stripe
                >
                  <el-table-column
                    label="序号"
                    align="center"
                    type="index"
                    :index="indexMethod"
                    width="100px"
                  />
                  <el-table-column
                    label="直播课程名称"
                    align="left"
                    prop="liveCourseName"
                    show-overflow-tooltip
                    min-width="200"
                  />
                  <el-table-column
                    label="直播课节名称"
                    align="left"
                    show-overflow-tooltip
                    prop="liveLessionName"
                    min-width="200"
                  />
                  <el-table-column
                    label="认证类型"
                    align="left"
                    prop="faceType"
                    show-overflow-tooltip
                    min-width="100"
                  ><template slot-scope="scope">
                      {{$setDictionary("LIVE_FACE_TYPE", scope.row.faceType || "--")}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="认证状态"
                    align="left"
                    prop="faceState"
                    show-overflow-tooltip
                    min-width="100"
                  >
                  <template slot-scope="scope">
                      {{ scope.row.faceState ? '成功':'失败'}}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作时间"
                    align="left"
                    prop="authTime"
                    show-overflow-tooltip
                    min-width="140"
                  >
                  <template slot-scope="scope">
                      {{scope.row.authTime | moment}}
                  </template>
                  </el-table-column>
                  <el-table-column
                    label="照片"
                    align="left"
                    prop="approveFace"
                    show-overflow-tooltip
                    min-width="140"
                  >
                  <template slot-scope="scope">
                      <el-image
                      v-if="scope.row.approveFace"
                    class="theImgno"
                    style="width: 50px; height: 50px"
                    fit="fill"
                    :src="scope.row.approveFace"
                    :preview-src-list="[scope.row.approveFace]"
                  >
                  </el-image>
                  </template>
                  </el-table-column>
                  <Empty slot="empty" />
                </el-table>
              </div>
            </div>
            <PageNum
              :apiData="apiData"
              @sizeChange="sizeChange"
              @getData="getData"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "liveClassStudentListLearningRecords",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 初始化显示学习记录
      activeName: "first",
      // 主键id
      liveProjectUserId: "",
      // 基本信息
      essentialInformationData: {},
      // 列表数据
      listData: [],
      // 数据检索
      retrievalData: {
          liveCourseName:'',//直播课程名称
        liveLessionName: "", // 直播课节名称
        faceState: "", // 学习状态
        faceType:'',
      },
      // 状态 - 下拉数据
      livefaceList: [],
      faceStateList:[{
          value:true,
          label:'成功'
      },{
           value:false,
          label:'失败'
      }]
    };
  },
  methods: {
    // 获取 - 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        liveProjectId:sessionStorage.getItem("liveProjectId"),
        userId:JSON.parse(this.$route.query.info).userId,
        faceState:this.retrievalData.faceState,
      };
      if(this.retrievalData.liveCourseName) {
      params.liveCourseName = this.retrievalData.liveCourseName;
      }
      if(this.retrievalData.liveLessionName) {
      params.liveLessionName = this.retrievalData.liveLessionName;
      }
      if(this.retrievalData.faceType) {
      params.faceType = this.retrievalData.faceType;
      }
      this.doFetch(
        {
          url: "/userface/live-user-face/page",
          params,
          pageNum,
        },
        true,
        3
      );
    },
    // 获取学习状态
    getcompleteList() {
      const livefacelist = this.$setDictionary("LIVE_FACE_TYPE", "list");
      const list = [];
      for (const key in livefacelist) {
        list.push({
          value: key,
          label: livefacelist[key],
        });
      }
      this.livefaceList = list;
    },
    // 列表学习状态 - 字段赋值
    getAuditName(value) {
      for (let i in this.complete) {
        if (this.complete[i].value == value) {
            return this.complete[i].label;
        }
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55 +3 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  created() {
    this.liveProjectUserId = JSON.parse(
      this.$route.query.info
    ).liveProjectUserId;
    this.essentialInformationData = JSON.parse(this.$route.query.info);
    this.getcompleteList();
  },
};
</script>
<style lang="less">
.framePage-body .infoDatas {
  line-height: 30px;
  .s2 {
    margin-right: 30px;
    margin-left: 5px;
  }
  .el-form-item {
      margin-bottom: 0;
  }
}
</style>